import React from 'react'
import {FaFacebookF,FaTwitter,FaGooglePlusG,FaInstagram,FaLinkedinIn,FaPhone,FaPrint} from 'react-icons/fa'
import {HiBuildingOffice2} from 'react-icons/hi2'
import{SlEnvolopeLetter} from 'react-icons/sl';
import{HiArrowCircleRight} from 'react-icons/hi'
import{GiRotaryPhone} from 'react-icons/gi'
import logo1 from './Images/Logo.png';
import { Button } from 'react-bootstrap';
import './App.css';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  let navigate = useNavigate(); 
  const about = () =>{ 
  let path = `/about`; 
  navigate(path);
}

  return (
<>  
  <div className='container-fluid bg-dark text-light'>
      <div className="text-center text-md-start mt-5">
        <div className="row">
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-2">
            <div className="section-title text-uppercase fw-bold m-3">Products</div>
            <p><a href="/product/Rfidreader" className="footer-text">RFID Readers</a></p>
            <p><a href="/product/Rfidantenna" className="footer-text">RFID Antenna</a></p>
            <p><a href="/product/Rfidtags" className="footer-text">RFID Tags and Cards</a></p>
            <p><a href="/product/Rfidprinter" className="footer-text">RFID Printer</a></p>
            <p><a href="/product/barcodescanner" className="footer-text">Barcode Scanners</a></p>
            <p><a href="/product/barcodeprinter" className="footer-text">Barcode Printers</a></p>
            <p><a href="/product/mobilecomputer" className="footer-text">Mobile Computers</a></p>
            <p><a href="/product/biometric" className="footer-text">Biometric Reader</a></p>
            <p><a href="/product/accesscontrol" className="footer-text">Access Control</a></p>
            <p><a href="/product/consumables" className="footer-text">Consumables</a></p>
          </div>
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-2">
              <div className="section-title text-uppercase fw-bold m-3">Solutions</div>
              <p><a href="/solutions/warehouse" className="footer-text">Warehouse Solution</a></p>
              <p><a href="/solutions/animal" className="footer-text">Animal Tracking Solution</a></p>
              <p><a href="/solutions/tyre" className="footer-text">Tyre Tracking Solution</a></p>
              <p><a href="/solutions/assettracking" className="footer-text">Asset Tracking Solution</a></p>
              <p><a href="/solutions/library" className="footer-text">Library management</a></p>
              <p><a href="/solutions/Jewellery" className="footer-text">Jewellery Tracking </a></p>
              <p><a href="/solutions/garments" className="footer-text">Garments Tracking </a></p>
              <p><a href="/solutions/retail" className="footer-text">Retail Solution </a></p>
              <p><a href="/solutions/file" className="footer-text">File racking </a></p>
              <p><a href="/solutions/cylinder" className="footer-text">Cylinder Tracking </a></p>
          </div>
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-2">
              <div className="section-title text-uppercase fw-bold m-3">Contact</div>
              <p className='footer-text'><HiBuildingOffice2/> Plot No 145 & 146, S2-2nd Floor, 3rd Street, Rajeev Nagar, Vanagaram, Chennai, Tamil Nadu 600077. <br/><GiRotaryPhone/>+044-42126904</p>
              <p className='footer-text'><HiBuildingOffice2/> Sardhi Residency, Dowa Colony  First floor Miypur, hyderabad, Telangana -500049. <br/><GiRotaryPhone/>+040-31613537</p>
              <p className='footer-text'><SlEnvolopeLetter/> info@rspm.co.in <br/> support@rspm.co.in <br/>Hyderabad@rspm.co.in</p>
              <p className='footer-text'><FaPhone/> + 91 9940021769, <br/>  +91 9176241275, <br/> + 91 7904726741</p>
          </div>
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-2">
              <div className="footer-logo-name text-uppercase fw-bold m-3">
                <img src ={logo1} alt="Logo1"  className="d-inline-block"/>
                </div>
              <p className='footer-text'>
              High-quality RFID for you! Whenever and whatever you need, we can provide the best solution for our customers.
              </p>
              <Button className='d-flex btn footer-btn btn-info rounded-pill p-2 m-1 ' onClick= {about}  style={{ width: 'Auto-content' }}>
              <div className='ms-2'>To Know RSPM More</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
              </Button>
          </div>
        </div>
      </div>
      <div className=" row row-col text-center border-top mb-3">
        <span className='m-2'><h4>REACH US</h4></span>
        <div className='social-icon'>
              <a href="https://www.facebook.com/people/RSPM-Infotech-RFID/100090927613918/" target="blank" className="m-2 text-reset">
                <span ><FaFacebookF className='social-iconfb'/></span> 
              </a>
              <a href="https://twitter.com/" target="blank" className="m-2 text-reset">
                <span ><FaTwitter className='social-icontw'/></span>
              </a>
              <a href="/#" target="blank" className="m-2 text-reset">
                <span ><FaGooglePlusG className='social-icongp'/></span>
              </a>
              <a href="/#" target="blank" className="m-2 text-reset">
                <span ><FaInstagram className='social-iconIn'/></span>
              </a>
              <a href="https://in.linkedin.com/company"  target='blank' className="m-2 text-reset">
                <span ><FaLinkedinIn className='social-iconLn'/></span>
              </a>
              
        </div>
      </div>
      <div className="container-fluid copy-right-bg border-top">
        <div className='text-center fw-bold '>© Copyright {new Date().getFullYear()} All rights reserved RSPM Infotech</div>
      </div>
  </div>
  <br/>

</>
  )
}

export default Footer