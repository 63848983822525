import React from 'react'
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import background from './Images/contact.jpg';
import './App.css';
import { MdEmail } from 'react-icons/md';
import { GrMapLocation } from 'react-icons/gr';
import { HiArrowCircleRight } from 'react-icons/hi';
import Footer from './Footer';
import ScrollButton from './Home/Scrollbutton';
import { ImHome } from 'react-icons/im';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
const Contactus = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_64uuvef', 'template_z3keoko', form.current, '913c97SRuVQ8wrlEz')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <>
      <div className='container'>
        <div className="mb-0">
          <figure className='position-relative'>
            <img src={background} alt="background" className='img-fluid' />
            <p className='contact-banner-head display-1 '>CONTACT RSPM</p>
            <div className='d-flex p-1'>
              <ImHome className='m-1' />
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"> <a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Contactus</li>
                </ol>
              </nav>
            </div>
          </figure>
        </div>
      </div>
      <div className='container'>
        <div className='contact-back bg-light p-2'>
          <div className='row'>
            <div className='col-md-5'>
              <h1 className="contact-head mt-3">How can RSPM help you?</h1>
              <div className="contact-title mt-3">
                We invite you to get in contact with us. Just send us a message or give us a call. You are always welcome to come over to one of our global offices and have an inspiring cup of coffee (or something else).
              </div>
              <div className='content-item my-3'>
                <div className='mb-3'>
                  <div className='d-flex'>
                    <div className='flex'><MdEmail /></div>
                    <div className='content-info ms-1'> Email:</div>
                  </div>
                  <div className='ms-4'>
                    <div>info@rspm.co.in</div>
                    <div>support@rspm.co.in</div>
                    <div>Hyderabad@rspm.co.in</div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='flex'><GrMapLocation /></div>
                  <div className='content-info ms-1'> Head Office Address:</div>
                </div>
                <p className='ms-4'>Plot No 145 & 146, S2-2nd Floor, 3rd Street, Rajeev Nagar, Vanagaram, Chennai, Tamil Nadu 600077. Phone: 044 42126904 , +91 9940021769 , +91 9176241275</p>
                <div className='d-flex'>
                  <div className='flex'><GrMapLocation /></div>
                  <div className='content-info ms-1'> Branch Office:</div>
                </div>
                <p className='ms-4'>Sardhi Residency, Dowa Colony  First floor Miypur, hyderabad, Telangana -500049. Phone: 040-31613537</p>
                {/* <div className='d-flex'>
                  <div className='flex'><GrMapLocation /></div>
                  <div className='content-info ms-1'> R&D Center Address:</div>
                </div>
                <p className='ms-4'>No15, Sri Moogambigai Nagar, Chickkarayapuram, Kovur EB, Chennai, Tamil Nadu 600069. Phone: +91 7904726741</p> */}
              </div>

            </div>
            <div className='col-md-7'>
              <form ref={form} onSubmit={sendEmail}>
                <div className='row '>
                  <div className='col-md-6'>

                    <Form.Group className="mt-3" controlId="formBasicName">
                      <Form.Control type="text" name="user_name" placeholder="* Name" required />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mt-3 " name="user_email" controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="* Email" required />
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group className="mt-3" controlId="formBasicPhone">
                      <Form.Control type="text" placeholder="* Phone" required />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mt-3" controlId="formBasicCompany">
                      <Form.Control type="text" placeholder="* Company" required />
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <FloatingLabel className="mt-3" controlId="floatingTextarea2" label="*Message">
                      <Form.Control as="textarea" placeholder="* Message" style={{ height: '200px' }} required />
                    </FloatingLabel>
                  </div>
                </div>
                <Button className="d-flex shadow-lg p-3 contact btn btn-primary my-3" type="submit" style={{ width: 'Auto-content' }}>
                  <div className='ms-2'>Submit</div><div className='icon flex ms-2'><HiArrowCircleRight /></div>
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container">

        <h1 className="contact-head mt-5">Where is RSPM?</h1>
        <div className='mt-3 d-flex '>
          <div className="col-lg-6 me-5 ">
            <h4 className='Prod-head-title'>Head Office Address</h4>
            <iframe title='myframe'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7773.373264225582!2d80.13603769357911!3d13.05560780000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525de2270e9f07%3A0xb7deb7e4a061a427!2sRSPM%20INFOTECH%20RFID%20%26%20IoT%20SOLUTIONS%20PROVIDER!5e0!3m2!1sen!2sin!4v1714543677837!5m2!1sen!2sin"
              style={{ height: '400px', width: '90%' }} />
          </div>
          {/* <p className='my-5'> S2, Second Floor, 145 & 146, 3rd St, Rajiv Nagar, Vanagaram, Chennai, Adayalampattu, Tamil Nadu 600077</p> */}



          <div className="col-lg-6 ">
            <h4 className='Prod-head-title'>Branch Office Address</h4>
            <iframe title='myframe'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.845445926283!2d80.14812911464603!3d13.04550799080742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52612451c0776d%3A0xf33826504e6fe6f9!2sRSPM%20INFOTECH!5e0!3m2!1sen!2sin!4v1674543021502!5m2!1sen!2sin"
              style={{ height: '400px', width: '90%' }} />
          </div>



        </div>



        {/* <p className='my-5'> 42/1, Ground Floor, Swami Vivekananda 2nd Street, Samayapuram Main Rd, Porur, Chennai, Tamil Nadu 600116</p> */}

      </div>
      <Footer />
      <ScrollButton />
    </>

  )
}

export default Contactus